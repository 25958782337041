//slider in top-section
$('.top-section-slider__list').bxSlider({
  mode: 'fade',
  auto: true,
  // responsive: false
});

$('#card-slider-bx').bxSlider({
  auto: true,
  pagerCustom: '#card-slider-pager-bx'
});

//stone slider in index.html
(function ($) {
  window.onload = function () {
    $("#tS1").thumbnailScroller({
        scrollerType:"hoverAccelerate", //values: "hoverPrecise", "hoverAccelerate", "clickButtons"
        scrollerOrientation:"horizontal", //values: "horizontal", "vertical"
        scrollEasing:"easeOutCirc", //easing type
        scrollEasingAmount:80000, //value: milliseconds
        acceleration:1, //value: integer
        scrollSpeed:600, //value: milliseconds
        noScrollCenterSpace:100, //value: pixels
        autoScrolling:1, //value: integer
        autoScrollingSpeed:80000, //value: milliseconds
        autoScrollingEasing:"easeInOutQuad", //easing type
        autoScrollingDelay:2500 //value: milliseconds
    });
  }
})(jQuery);

//custom selectbox
$( ".selectmenu" ).selectmenu();

//card slider
$(document).ready(function() {
  $("#card-slider").lightSlider({
    item:1,
    thumbItem:10,
    gallery: true,
    thumbMargin: 8,
        auto: true,
        loop: true,
        pause: 5000,
    onSliderLoad: function(el) {
    el.lightGallery({
    });
}
  });
});

//gallery lightbox
$(function() {
    lightbox.option({
      'wrapAround': true,
    });
});

// GoogleMap
google.maps.event.addDomListener(window, 'load', init);
var map;
function init() {
    var mapOptions = {
        center: new google.maps.LatLng(60.059817, 30.362459),
        zoom: 12,
        zoomControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.DEFAULT,
        },
        disableDoubleClickZoom: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        },
        scaleControl: true,
        scrollwheel: false,
        panControl: true,
        streetViewControl: true,
        draggable: true,
        overviewMapControl: true,
        overviewMapControlOptions: {
            opened: false,
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"color":"#f7f1df"}]},{"featureType":"landscape.natural","elementType":"geometry","stylers":[{"color":"#d0e3b4"}]},{"featureType":"landscape.natural.terrain","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.medical","elementType":"geometry","stylers":[{"color":"#fbd3da"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#bde6ab"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffe15f"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#efd151"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"color":"black"}]},{"featureType":"transit.station.airport","elementType":"geometry.fill","stylers":[{"color":"#cfb2db"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#a2daf2"}]}],
    }
    var mapElement = document.getElementById('map');
    var map = new google.maps.Map(mapElement, mapOptions);
    var marker = new google.maps.Marker({
        // Определяем позицию маркера
        position: {
            lat: 60.059817,
            lng: 30.362459
        },
        map: map,
        // icon: 'assets/img/marker.png'
    });
    var locations = [

    ];
    for (i = 0; i < locations.length; i++) {
        if (locations[i][1] == 'undefined') { description = ''; } else { description = locations[i][1]; }
        if (locations[i][2] == 'undefined') { telephone = ''; } else { telephone = locations[i][2]; }
        if (locations[i][3] == 'undefined') { email = ''; } else { email = locations[i][3]; }
        if (locations[i][4] == 'undefined') { web = ''; } else { web = locations[i][4]; }
        if (locations[i][7] == 'undefined') { markericon = ''; } else { markericon = locations[i][7]; }
        marker = new google.maps.Marker({
            icon: markericon,
            position: new google.maps.LatLng(locations[i][5], locations[i][6]),
            map: map,
            title: locations[i][0],
            desc: description,
            tel: telephone,
            email: email,
            web: web
        });
        link = '';
    }

}
